(function($){
    'use strict';

    var $printButton = $('.share .print');

    if($printButton.length) {
        $printButton.click(function() {
            window.print();
            return false;
        });
    }
}(jQuery));
