'use strict';

function _toConsumableArray(arr) {
  return (
    _arrayWithoutHoles(arr) ||
    _iterableToArray(arr) ||
    _unsupportedIterableToArray(arr) ||
    _nonIterableSpread()
  );
}

function _nonIterableSpread() {
  throw new TypeError(
    'Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.'
  );
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === 'string') return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === 'Object' && o.constructor) n = o.constructor.name;
  if (n === 'Map' || n === 'Set') return Array.from(o);
  if (n === 'Arguments' || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen);
}

function _iterableToArray(iter) {
  if (typeof Symbol !== 'undefined' && Symbol.iterator in Object(iter))
    return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function ATPMap() {
  // Module
  this.module = document.querySelector('.js-atp-search');
  this.gitBridgeId = this.module.getAttribute('data-gitbridge'); // Form
  
  this.form = this.module.querySelector('.js-atp-form'); // Google Map
  
  this.map = this.module.querySelector('.js-atp-map');
  this.google = {};
  this.google.map = false;
  this.google.key = this.form.dataset.googleMapKey;
  this.markers = null;
  this.addressMarker = null;
  this.markerIcon =
    this.gitBridgeId != 0
      ? '?a=' + this.gitBridgeId + '%3Adeployment%2Fmysource_files%2Fmarker.svg'
      : './mysource_files/marker.svg';
  this.addressMarkerIcon =
    this.gitBridgeId != 0
      ? '?a=' +
        this.gitBridgeId +
        '%3Adeployment%2Fmysource_files%2FaddressMarker.svg'
      : './mysource_files/addressMarker.svg';
  this.clusterPath =
    this.gitBridgeId != 0
      ? '?a=' + this.gitBridgeId + '%3Adeployment%2Fmysource_files%2Fcluster'
      : './mysource_files/cluster';
  this.activeMarker = null;
  this.nswBounds = {
    north: -30.47288387075809,
    south: -36.3023457865061,
    east: 154.31752050768316,
    west: 147.18739355455816,
  };
  this.maxBounds = {
    north: -10,
    south: -39.5,
    east: 155,
    west: 111,
  }; // Google Address Autocomplete

  this.google.autocompleteService = null;
  this.google.placesService = null;
  this.google.keywords = this.module.querySelector('.js-address-keywords');
  this.addressAutocomplete = this.module.querySelector(
    '.js-address-autocomplete'
  ); // Marker clusterer

  this.markerCluster = null; // Info Window

  this.infoWindow = this.module.querySelector('.js-infowindow');
  this.infoWindowClose = this.module.querySelector('.js-infowindow-close'); // Search bar

  this.search = this.module.querySelector('.js-search'); // Location results

  this.results = this.module.querySelector('.js-result');
  this.results.list = this.results.querySelector('.js-result-list'); // Map / List option

  this.results.message = this.results.list.querySelector('.js-result-message');
  this.options = this.module.querySelector('.js-options');
  this.options.map = this.options.querySelector('.js-option-map');
  this.options.list = this.options.querySelector('.js-option-list'); // Controls

  this.controls = this.module.querySelector('.js-controls');
  this.controls.zoomIn = this.controls.querySelector('.js-zoom-in');
  this.controls.zoomOut = this.controls.querySelector('.js-zoom-out'); // Google map element

  this.mapElement = this.module.querySelector('.js-atp-map'); //input field

  this.addressInput = this.form.querySelector('.js-google-query');
  this.searchInput = this.form.querySelector('.js-funnelback-query');
  this.autocompleteInput = '.tt-hint'; // funnelback atp autocomplete

  this.atpKeywords = this.module.querySelector('.js-search-keywords');
  this.atpSearchUrl =
    this.form.getAttribute('data-partial-url') +
    '?collection=' +
    this.form.getAttribute('data-partial-collection') +
    '&form=' +
    this.form.getAttribute('data-partial-form') +
    '&num_ranks=' +
    this.form.getAttribute('data-partial-results'); // search type buttons

  this.searchType = this.module.querySelector('.js-type-buttons');
  this.addressToggle = this.searchType.querySelector('.js-address-toggle');
  this.addressSelect = this.searchType.querySelector('.js-address-select');
  this.addressRanges = this.searchType.querySelector('.js-address-ranges');
  this.searchToggle = this.searchType.querySelector('.js-search-toggle'); // Filter

  this.filters = this.module.querySelector('.js-filters');
  this.filterToggles = Array.prototype.slice.call(
    this.filters.querySelectorAll('input[type="checkbox"],input[type="radio"]')
  );
  this.filterRegex = null;
  this.filterCategories = [];
  this.filterButton = this.module.querySelector('.js-filter-button');
  this.filterClose = this.module.querySelector('.js-filter-close');
}

ATPMap.prototype.init = function () {
  this._handleEvents();
  this._buildFilterRegex();
  this._initMap();
  this._initFunnelbackAutocompletion();
};

ATPMap.prototype._toggleMarkerActive = function (event, marker, feature) {
  if (marker === this.activeMarker || !marker) {
    this._removeAnimation(this.activeMarker);

    this.activeMarker = null;

    this._closeInfoWindow();
  } else if (marker) {
    this._closeInfoWindow();

    this._updateInfoWindow(feature, marker);

    this._openInfoWindow();

    this._makeBounce(marker);

    this._removeAnimation(this.activeMarker);

    this.activeMarker = marker;
  }
};

ATPMap.prototype._makeBounce = function (marker) {
  marker.setAnimation(google.maps.Animation.BOUNCE);
};

ATPMap.prototype._makeDrop = function (marker) {
  marker.setAnimation(google.maps.Animation.DROP);
};

ATPMap.prototype._removeAnimation = function (marker) {
  if (marker) {
    marker.setAnimation(null);
  }
};

ATPMap.prototype._addFeatureEvents = function () {
  this.google.map.addListener('click', this._toggleMarkerActive.bind(this));
};

ATPMap.prototype._initInfoWindow = function () {
  this.infoWindow = new google.maps.InfoWindow({
    content: this.infoWindow,
    pixelOffset: new google.maps.Size(0, -50),
  });
};

ATPMap.prototype._openInfoWindow = function () {
  this.infoWindow.open(this.google.map);
};

ATPMap.prototype._closeInfoWindow = function () {
  this.infoWindow.close();
};

ATPMap.prototype._updateInfoWindow = function (feature, marker) {
  var content = this.infoWindow.getContent();

  this._hideAllInfoWindowFields(content);

  var svg = content.querySelector(
    '[data-infowindow-attr="address"] .js-infowindow-svg'
  ).innerHTML;
  feature.forEachProperty(
    function (value, key) {
      var target = content.querySelector(
        '[data-infowindow-attr="' + key + '"] .js-infowindow-value'
      );

      switch (key) {
        case 'providerName':
          target = content.querySelector(
            '[data-infowindow-attr="' + 'title' + '"] .js-infowindow-value'
          );

          if (target) {
            target.innerHTML = value;

            this._showInfoWindowField(content, 'title');
          } else {
            console.warn('Unhandled property: ', key);
          }

          break;

        case 'contactEmail':
          if (target) {
            target.innerHTML =
              '<a href="mailto:' + value + '">' + value + '</a>';

            this._showInfoWindowField(content, key);
          } else {
            console.warn('Unhandled property: ', key);
          }

          break;

        case 'contactPhone':
          if (target) {
            target.innerHTML = '<a href="tel:' + value + '">' + value + '</a>';

            this._showInfoWindowField(content, key);
          } else {
            console.warn('Unhandled property: ', key);
          }

          break;

        case 'website':
          if (target) {
            target.innerHTML =
              '<a href="' + value + '" target="_blank">' + value + '</a>';

            this._showInfoWindowField(content, key);
          } else {
            console.warn('Unhandled property: ', key);
          }

          break;

        case 'address':
          if (target) {
            var addresses = [value],
              latLngs = [
                feature.getProperty('latLng')
                  ? feature.getProperty('latLng')
                  : '',
              ];
            addresses = addresses.concat(
              feature.getProperty('otherAddresses')
                ? feature.getProperty('otherAddresses').split('|')
                : ''
            );
            latLngs = latLngs.concat(
              feature.getProperty('otherLatLng')
                ? feature
                    .getProperty('otherLatLng')
                    .replace(/,/gi, ';')
                    .split('|')
                : ''
            );
            target.innerHTML = '';
            this._createInfoWindowAddresses(addresses, latLngs, svg).forEach(
              function (child) {
                target.appendChild(child);
              }
            );

            this._showInfoWindowField(content, key);
          } else {
            console.warn('Unhandled property: ', key);
          }

          break;

        case 'postcode':
          if (target) {
            value = value.split('|').join(', ');
            target.innerHTML = feature.getProperty('state')
              ? feature.getProperty('state') + ' ' + value
              : value;

            this._showInfoWindowField(content, key);
          } else {
            console.warn('Unhandled property: ', key);
          }

          break;

        case 'courses':
          if (target) {
            value = value.split('|').join(' & ');
            target.innerHTML = value;

            this._showInfoWindowField(content, key);
          } else {
            console.warn('Unhandled property: ', key);
          }

          break;

        case 'latLng':
          // ignore
          break;

        case 'otherLatLng':
          // ignore
          break;

        case 'otherAddresses':
          // ignore
          break;

        case 'state':
          // ignore
          break;

        default:
          if (target) {
            value = value.split('|').join(', ');
            target.innerHTML = value;

            this._showInfoWindowField(content, key);
          } else {
            console.warn('Unhandled property: ', key);
          }

          break;
      }
    }.bind(this)
  );
  this.infoWindow.setPosition(marker.getPosition());
};

ATPMap.prototype._createInfoWindowAddresses = function (
  addresses,
  latLngs,
  svg
) {
  var content = [];
  addresses.forEach(
    function (address, index) {
      if (
        address !== '' &&
        this.markers[latLngs[index]] &&
        this.markers[latLngs[index]].marker.getVisible()
      ) {
        var anchor = document.createElement('A');
        anchor.setAttribute('href', '#');
        anchor.setAttribute('data-latlng', latLngs[index]);
        anchor.classList.add('js-infowindow-address');
        anchor.innerHTML = svg + '<span>' + address + '</span>';
        anchor.addEventListener('click', this._goToMarker.bind(this));
        content.push(anchor);
      }
    }.bind(this)
  );
  return content;
};

ATPMap.prototype._goToMarker = function (e) {
  e.preventDefault();
  var target = e.currentTarget,
    latLng = target.getAttribute('data-latlng'),
    obj = this.markers[latLng];

  if (obj) {
    if (
      obj.marker != this.activeMarker &&
      obj.marker.getVisible() &&
      obj.feature
    ) {
      // Marker is visible
      this._goToBounds(this._createBounds(obj.marker));

      this._toggleMarkerActive(null, obj.marker, obj.feature);
    } else if (obj.marker) {
      // Marker is not visible
      this.google.map.panTo(obj.marker.getPosition());
    } else {
      // No marker
      latLng = latLng.split(';');

      this._panTo(latLng);
    }
  } else {
    // No feature
    latLng = latLng.split(';');

    this._panTo(latLng);
  }
};
/**
 * Converts string in the format of to latLng to pan to in google maps
 */

ATPMap.prototype._panTo = function (array) {
  var coords = new google.maps.LatLng({
    lat: parseFloat(array[0]),
    lng: parseFloat(array[1]),
  });
  this.google.map.panTo(coords);
};

ATPMap.prototype._hideAllInfoWindowFields = function (infowindow) {
  var fields = Array.prototype.slice.call(
    infowindow.querySelectorAll('[data-infowindow-attr]')
  );
  fields.forEach(
    function (field) {
      field.classList.add('hidden');
    }.bind(this)
  );
};

ATPMap.prototype._showInfoWindowField = function (infowindow, key) {
  infowindow
    .querySelector('[data-infowindow-attr="' + key + '"]')
    .classList.remove('hidden');
};

ATPMap.prototype._initMap = function () {
  // Create the script tag, set the appropriate attributes
  var mapScript = document.createElement('script'),
    mapOptions = {
      url: 'https://maps.googleapis.com/maps/api/js',
      params: {
        key: this.google.key,
        map_ids: '76f448635e95c4ac',
        libraries: 'places',
        callback: 'initMap',
      },
    },
    markerScript = document.createElement('script'),
    markerOptions = {
      url:
        'https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js',
      params: {},
    };
  mapScript.src = this._buildSrc(mapOptions);
  mapScript.defer = true;
  markerScript.src = this._buildSrc(markerOptions);
  markerScript.defer = true; // Attach your callback function to the `window` object

  window.initMap = function () {
    // JS API is loaded and available
    var center = {
      lat: -25.274398,
      lng: 133.775136,
    };

    this.google.map = new google.maps.Map(this.map, {
      zoom: 4,
      center: center,
      mapId: '76f448635e95c4ac',
      mapTypeId: 'roadmap',
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      zoomControl: false,
      minZoom: 4,
      maxZoom: 18,
    });

    this._initZoomControl(this.google.map);

    this._initInfoWindow();

    this._addFeatureEvents();

    this._initMarkerCluster();

    this._initPlacesServices(); // Fire initial search to load all results

    this._handleSubmit();
  }.bind(this); // Append the 'script' element to 'head'

  document.head.appendChild(mapScript);
  document.head.appendChild(markerScript);
};

ATPMap.prototype._initMarkerCluster = function () {
  this.markerCluster = new MarkerClusterer(this.google.map, [], {
    imagePath: this.clusterPath,
    imageExtension: 'svg',
    clusterClass: 'atp-search__cluster',
  });
};

ATPMap.prototype._initZoomControl = function (map) {
  this.controls.zoomIn.addEventListener('click', function (e) {
    e.preventDefault;
    e.stopPropagation();
    map.setZoom(map.getZoom() + 1);
  });
  this.controls.zoomOut.addEventListener('click', function (e) {
    e.preventDefault;
    e.stopPropagation();
    map.setZoom(map.getZoom() - 1);
  });
  map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
    this.controls.querySelector('.js-zoom-control')
  );
};

ATPMap.prototype._handleEvents = function () {
  this.form.addEventListener('submit', this._handleSubmit.bind(this));
  this.options.map.addEventListener('click', this._mapToggle.bind(this));
  this.options.list.addEventListener('click', this._listToggle.bind(this));
  this.filterButton.addEventListener('click', this._toggleFilters.bind(this));
  this.filterClose.addEventListener('click', this._hideFilters.bind(this));
  this.filterToggles.forEach(
    function (filter) {
      filter.addEventListener('change', this._filterMarkers.bind(this));
    }.bind(this)
  );
  this.infoWindowClose.addEventListener(
    'click',
    this._toggleMarkerActive.bind(this)
  );
  this.addressSelect.addEventListener(
    'click',
    this._toggleAddressRangeMenu.bind(this)
  );
  Array.prototype.slice
    .call(this.addressRanges.querySelectorAll('.atp-search__address-option'))
    .forEach(
      function (elem) {
        elem.addEventListener(
          'click',
          this._updateAddressRangeSelection.bind(this)
        );
        elem.addEventListener(
          'keydown',
          this._updateAddressRangeSelection.bind(this)
        );
      }.bind(this)
    );

  this._handleTypeToggle();

  document.addEventListener('click', this._handleNotAddressClick.bind(this));
  document.addEventListener(
    'click',
    this._handleNotAddressRangesClick.bind(this)
  );
  document.addEventListener('click', this._handleNotFilterClick.bind(this));
  document.addEventListener(
    'keydown',
    this._handleAddressNavigation.bind(this)
  );
  this.addressInput.addEventListener(
    'focusin',
    this._openAddressKeywordsMenu.bind(this)
  );
};
/**
 * Builds the Google Maps script url
 * @param {object} options
 */

ATPMap.prototype._buildSrc = function (options) {
  var url = '',
    params = Object.keys(options.params);
  url += options.url;
  params.forEach(function (param, index) {
    if (index < 1) {
      url += '?' + param + '=' + options.params[param];
    } else {
      url += '&' + param + '=' + options.params[param];
    }
  });
  return url;
};
/**
 * Build regex for filtering
 */

ATPMap.prototype._buildFilterRegex = function () {
  var regexString = '',
    regexFlags = 'i',
    regexBuiltValues = [],
    condition = '';
  this.filterToggles.forEach(
    function (toggle) {
      if (toggle.checked) {
        var filter = toggle.getAttribute('data-filter');

        if (filter) {
          regexBuiltValues.push(filter);
        } else if (toggle.getAttribute('data-condition')) {
          condition = toggle.getAttribute('data-condition');
        }
      }
    }.bind(this)
  );

  regexBuiltValues.forEach(
    function (value) {
      if (this._notEmpty(regexString)) {
        regexString += condition + '(?=.*(' + value + ')([\\s,|;]|$))';
      } else {
        regexString += '(?=.*(' + value + ')([\\s,|;]|$))';
      }
    }.bind(this)
  );
  // If all unchecked, add default value so nothing matches empty regex test
  if (!this._notEmpty(regexString)) {
    regexString = '(?=.*(!padrenull)([\\s,|;]|$))';
  }

  regexString = '^(' + regexString + ').*$';
  this.filterRegex = new RegExp(regexString, regexFlags);
};

ATPMap.prototype._notEmpty = function (item) {
  if (item !== undefined && item !== '' && item !== null && item.length > 0) {
    return true;
  }

  return false;
};
/**
 * Toggle Map View
 */

ATPMap.prototype._mapToggle = function (e) {
  e.preventDefault();
  this.search.classList.add('atp-search__form--absolute');
  this.options.classList.add('atp-search__location-options--absolute');
  this.map.classList.add('active');
  this.results.classList.remove('active');
  this.options.list.classList.remove('active');
  this.options.map.classList.add('active');
};
/**
 * Toggle List View
 */

ATPMap.prototype._listToggle = function (e) {
  e.preventDefault();
  this.search.classList.remove('atp-search__form--absolute');
  this.options.classList.remove('atp-search__location-options--absolute');
  this.map.classList.remove('active');
  this.results.classList.add('active');
  this.options.list.classList.add('active');
  this.options.map.classList.remove('active');
};
/**
 * Toggle marker
 */

ATPMap.prototype._toggleMarkerVisible = function (marker, toggle) {
  if (marker.getVisible() != toggle) {
    if (toggle) {
      this.markerCluster.addMarker(marker);
    } else {
      this.markerCluster.removeMarker(marker);
    }
  }

  marker.setVisible(toggle); // Hide infowindow if this is active

  if (marker == this.activeMarker && toggle == false) {
    this._closeInfoWindow();
  }
};
/**
 * remove all markers
 */

ATPMap.prototype._removeAllMarkers = function () {
  var _this = this;

  if (this.markers) {
    var pairs = Object.keys(_this.markers).map(function (key) {
      return _this.markers[key];
    });
    pairs.forEach(
      function (pair) {
        if (pair.marker) {
          pair.marker.setMap(null);
        }
      }.bind(this)
    );
  }

  if (this.addressMarker) {
    this.addressMarker.setMap(null);
    this.addressMarker = null;
  }
  this.markerCluster.clearMarkers();
  this.markers = null;
};
/**
 * Cluster google markers
 */

ATPMap.prototype._clusterMarkers = function () {
  this.markerCluster.clearMarkers();

  var markers = this._getMarkers();

  this.markerCluster.addMarkers(markers);
};
/**
 * Get markers from the marker object list
 */

ATPMap.prototype._getMarkers = function () {
  var _this2 = this;

  var markers = [];
  Object.keys(_this2.markers)
    .map(function (key) {
      return _this2.markers[key];
    })
    .forEach(
      function (obj) {
        markers.push(obj.marker);
      }.bind(this)
    );
  return markers;
};
/**
 * Handle form submit
 */

ATPMap.prototype._handleSubmit = function (e) {
  e ? e.preventDefault() : false;
  this._disableForm();
  this._executeQuery();
};

ATPMap.prototype._disableForm = function () {
  this.searchInput.setAttribute('disabled', true);
  this.addressInput.setAttribute('disabled', true);
  this.filterToggles.forEach(
    function (elem) {
      elem.setAttribute('disabled', true);
    }.bind(this)
  );
  Array.prototype.slice.call(this.form.querySelectorAll('button')).forEach(
    function (elem) {
      elem.setAttribute('disabled', true);
    }.bind(this)
  );
};

ATPMap.prototype._enableForm = function () {
  this.searchInput.removeAttribute('disabled');
  this.addressInput.removeAttribute('disabled');
  this.filterToggles.forEach(
    function (elem) {
      elem.removeAttribute('disabled');
    }.bind(this)
  );
  Array.prototype.slice.call(this.form.querySelectorAll('button')).forEach(
    function (elem) {
      elem.removeAttribute('disabled');
    }.bind(this)
  );
};

ATPMap.prototype._executeQuery = debounce(function () {
  var endpoint = this.form.getAttribute('data-endpoint');

  switch (endpoint) {
    case 'funnelback':
      var query =
        this.atpSearchUrl +
        '&query=' +
        (this.searchInput.value != ''
          ? '"' + this.searchInput.value + '"'
          : '!padrenull');

      this._updateMap(query);

      break;

    case 'google':
      this._placesQuery(this.addressInput.value);

      break;

    default:
      console.error('Unhandled endpoint');
      return false;
  }
}, 100);

ATPMap.prototype._getState = function (details) {
  var shortStateRegex = new RegExp(
      '(?=([\\s,])?)(\\bNSW\\b|\\bVIC\\b|\\bNT\\b|\\bACT\\b|\\bWA\\b|\\bSA\\b|\\bQLD\\b|\\bTAS\\b)(?=([\\s,])?)',
      'i'
    ),
    longStateRegex = new RegExp(
      '(?=([\\s,])?)(\\bNew South Wales\\b|\\bVictoria\\b|\\bNorthern Territory\\b|\\bAustralian Capital Territory\\b|\\bWestern Australia\\b|\\bSouth Australia\\b|\\bQueensland\\b|\\bTasmania\\b)(?=([\\s,])?)',
      'i'
    ),
    longStateClean = new RegExp('\\b\\w', 'gi'),
    addressComponents = details.address_components,
    formatted_address = details.formatted_address,
    shortState = [],
    longState = [],
    state = '';

  addressComponents.forEach(
    function (component) {
      if ((shortState = shortStateRegex.exec(component.short_name)) !== null) {
        state = shortState[0];
      }
    }.bind(this)
  );

  if (
    state == '' &&
    (longState = longStateRegex.exec(formatted_address)) !== null
  ) {
    var cleanArray = [];
    longState = longState[0];

    while ((cleanArray = longStateClean.exec(longState)) !== null) {
      state += cleanArray[0];
    }
  }

  if (state == '') {
    // Log error and default to NSW
    console.error(
      'Could not find state using address details. Default to NSW',
      details
    );
    state = 'NSW';
  }

  return state;
};

ATPMap.prototype._placesQuery = function (query) {
  this.google.placesService.findPlaceFromQuery(
    {
      fields: ['ALL'],
      query: query,
      locationBias: this.nswBounds,
    },
    function (results) {
      var query = this.atpSearchUrl,
        range = this.addressSelect.getAttribute('data-range');
      query += '&query=!padrenull';

      if (results && results.length > 0) {
        this.google.placesService.getDetails(
          {
            fields: ['ALL'],
            placeId: results[0].place_id,
          },
          function (details) {
            if (range !== 'all' || range !== 'state') {
              query += '&maxdist=' + range;
              query += '&fmo=true';
              query += '&sort=prox';
              query += '&meta_IsGlobal=false';
              query +=
                '&origin=' +
                details.geometry.location.lat() +
                ',' +
                details.geometry.location.lng();
            }
            if (range == 'state') {
              var state = this._getState(details);

              query += '&meta_State=' + state;
              query += '&fmo=true';
              query += '&meta_IsGlobal=false';
            }
            this._updateMap(query);
          }.bind(this)
        );
      } else {
        this._updateMap(query);
      }
    }.bind(this)
  );
};

ATPMap.prototype._initPlacesServices = function () {
  this.google.autocompleteService = new google.maps.places.AutocompleteService();
  this.google.placesService = new google.maps.places.PlacesService(
    this.addressAutocomplete
  );
  this.addressInput.addEventListener(
    'input',
    this._handleAddressInput.bind(this)
  );
};

ATPMap.prototype._openAddressKeywordsMenu = function (e) {
  if (
    Array.prototype.slice.call(
      this.google.keywords.querySelectorAll('.js-keywords-list a')
    ).length > 0
  ) {
    this.google.keywords.classList.add('active');
  }
};

ATPMap.prototype._handleAddressNavigation = function (e) {
  var keyDown = e.key == 'ArrowDown',
    keyUp = e.key == 'ArrowUp',
    inputFocussed = document.activeElement == this.addressInput,
    keywordsFocussed = this.google.keywords.contains(document.activeElement),
    focusable = Array.prototype.slice.call(
      Array.prototype.slice.call(
        this.google.keywords.querySelectorAll('.js-keywords-list a')
      )
    );

  if ((inputFocussed || keywordsFocussed) && (keyDown || keyUp)) {
    e.preventDefault();

    if (inputFocussed) {
      if (keyDown) {
        focusable[0].focus();
      }

      if (keyUp) {
        focusable[focusable.length - 1].focus();
      }
    }

    if (keywordsFocussed) {
      var index = focusable.indexOf(document.activeElement);

      if (keyDown && index == focusable.length - 1) {
        focusable[0].focus();
      } else if (keyDown) {
        focusable[index + 1].focus();
      }

      if (keyUp && index == 0) {
        focusable.reverse()[0].focus();
      } else if (keyUp) {
        focusable[index - 1].focus();
      }
    }

    this.addressInput.value = document.activeElement.text;
  }
};

ATPMap.prototype._handleAddressInput = function (e) {
  e.preventDefault();
  var target = e.currentTarget,
    keywordList = this.google.keywords.querySelector('.js-keywords-list');

  if (target.value != '') {
    this.google.autocompleteService.getPlacePredictions(
      {
        input: target.value,
        componentRestrictions: {
          country: 'au',
        },
      },
      function (results) {
        if (results) {
          var list = document.createElement('div');
          results.forEach(
            function (result) {
              var anchor = document.createElement('a');
              anchor.setAttribute('href', '#');
              anchor.classList.add('atp-search__keywords-suggestion');
              anchor.innerHTML = result.description;
              anchor.addEventListener(
                'click',
                this._handleAddressClick.bind(this)
              );
              list.appendChild(anchor);
            }.bind(this)
          );
          keywordList.innerHTML = '';
          keywordList.appendChild(list);

          this._openAddressList();
        } else {
          this._closeAddressList();
        }
      }.bind(this)
    );
  } else {
    this._closeAddressList();
  }
};

ATPMap.prototype._handleNotAddressClick = function (e) {
  var target = e.target;

  if (
    target != this.addressInput &&
    target != this.google.keywords &&
    !this.google.keywords.contains(target)
  ) {
    this._closeAddressList();
  }
};

ATPMap.prototype._handleAddressClick = function (e) {
  e.preventDefault();
  var target = e.currentTarget;
  this.addressInput.value = target.text;

  this._closeAddressList();

  this._handleSubmit();
};

ATPMap.prototype._openAddressList = function () {
  this.google.keywords.classList.add('active');
};

ATPMap.prototype._closeAddressList = function () {
  this.google.keywords.classList.remove('active');
};

ATPMap.prototype._initFunnelbackAutocompletion = function () {
  jQuery('.js-funnelback-query').autocompletion({
    datasets: {
      organic: {
        collection: 'liquor-and-gaming-map',
        profile: 'autocomplete',
        program: 'https://nswdfsi-search.squiz.cloud/s/suggest.json',
        format: 'extended',
        alpha: '0.5',
        show: '10',
        sort: '0',
        fmt: 'json',
        group: true,
        template: {
          suggestion: function (context) {
            var anchor = document.createElement('div');
            anchor.classList.add('atp-search__keywords-suggestion');
            anchor.innerHTML =
              '<a href="#">' + context.label.metaData.ProviderName + '</a>';
            anchor.addEventListener(
              'click',
              function (e) {
                e.preventDefault();
              }.bind(this)
            );
            return anchor;
          }.bind(this),
          header: '<div class="atp-search__keywords-title">Suggested</div>',
        },
      },
    },
    typeahead: {
      hint: true,
      classNames: {
        menu: 'atp-search__keywords',
        cursor: 'atp-search__keywords-suggestion--active',
      },
      events: {
        select: function () {
          this._handleSubmit();

          return false;
        }.bind(this),
      },
    },
    length: 2,
  });
  this.autocompleteInput =
    typeof this.autocompleteInput != 'string'
      ? this.autocompleteInput
      : this.form.querySelector(this.autocompleteInput);
};
/**
 * Update the map with the supplied GeoJSON data
 */

ATPMap.prototype._updateMap = function (data) {
  if (this.markers) {
    this._removeAllMarkers();
  }

  this.markers = new google.maps.Data();

  this._loadGeoJson(data, null).then(
    function (resp) {
      var endpoint = this.form.getAttribute('data-endpoint');

      switch (endpoint) {
        case 'funnelback':
          this._updateBounds();
          break;
        case 'google':
          this._getLatLng(this.addressInput.value);
          break;
        default:
          console.error('Unhandled endpoint');
          return false;
      }
    }.bind(this)
  );
};

ATPMap.prototype._getLatLng = function (address) {
  var geocoder = new google.maps.Geocoder();

  geocoder.geocode(
    {
      address: address,
      componentRestrictions: {
        country: 'au',
      },
    },
    function (resp) {
      var marker = new google.maps.Marker({
        position: resp[0].geometry.location,
        icon: {
          url: this.addressMarkerIcon,
          scaledSize: new google.maps.Size(26, 40),
        },
        animation: google.maps.Animation.DROP,
      });

      marker.setMap(this.google.map);

      this.addressMarker = marker;

      if (!this._testState(resp[0].address_components)) {
        this._goToBounds(this.maxBounds);
      } else if (resp[0].geometry.bounds) {
        this._goToBounds(resp[0].geometry.bounds);
      } else {
        this._goToBounds(this._createBounds(marker));
      }
    }.bind(this)
  );
};

ATPMap.prototype._testState = function (components) {
  var bool = false;

  components.forEach(
    function (component) {
      if (component.types.includes('administrative_area_level_1')) {
        bool = component.short_name == 'NSW' ? true : false;
      }
    }.bind(this)
  );

  return bool;
};

ATPMap.prototype._loadGeoJson = function (url, options) {
  var promise = new Promise(
    function (resolve, reject) {
      try {
        this.markers.loadGeoJson(
          url,
          options,
          function (features) {
            this.markers = Object.assign.apply(
              Object,
              [{}].concat(
                _toConsumableArray(
                  features.map(
                    function (feature) {
                      var obj = {},
                        marker = new google.maps.Marker({
                          position: feature.getGeometry().get(0),
                          icon: {
                            url: this.markerIcon,
                            scaledSize: new google.maps.Size(26, 40),
                          },
                          animation: google.maps.Animation.DROP,
                        });
                      marker.addListener(
                        'click',
                        function () {
                          this._toggleMarkerActive(null, marker, feature);
                        }.bind(this)
                      );
                      obj[feature.getProperty('latLng')] = {
                        feature: feature,
                        marker: marker,
                      };
                      return obj;
                    }.bind(this)
                  )
                )
              )
            );

            this._updateResultsList();

            this._closeInfoWindow();

            this._clusterMarkers();

            this._filterMarkers();

            this._enableForm();

            resolve(this.markers);
          }.bind(this)
        );
      } catch (e) {
        reject(e);
      }
    }.bind(this)
  );
  return promise;
};

ATPMap.prototype._createBounds = function (marker) {
  return {
    north: marker.getPosition().lat(),
    south: marker.getPosition().lat(),
    east: marker.getPosition().lng(),
    west: marker.getPosition().lng(),
  };
};
/**
 * Updates the bounds using the marker coordinates
 */

ATPMap.prototype._updateBounds = function () {
  var _this3 = this;

  var bounds = {
      north: null,
      south: null,
      east: null,
      west: null,
    },
    count = 0,
    hasNsw = false,
    nswRegex = /nsw/i,
    singleMarker = null;
  Object.keys(_this3.markers)
    .map(function (key) {
      return _this3.markers[key];
    })
    .forEach(function (obj) {
      if (obj.marker.getVisible()) {
        var position = obj.marker.getPosition();
        bounds.north =
          position.lat() > bounds.north || !bounds.north
            ? position.lat()
            : bounds.north;
        bounds.east =
          position.lng() > bounds.east || !bounds.east
            ? position.lng()
            : bounds.east;
        bounds.south =
          position.lat() < bounds.south || !bounds.south
            ? position.lat()
            : bounds.south;
        bounds.west =
          position.lng() < bounds.west || !bounds.west
            ? position.lng()
            : bounds.west;
        hasNsw += nswRegex.test(obj.feature.getProperty('state'));
        singleMarker = obj;
        count++;
      }
    });

  if (hasNsw && count == 1) {
    this._goToBounds(this._createBounds(singleMarker.marker));

    this._toggleMarkerActive(null, singleMarker.marker, singleMarker.feature);
  } else if (hasNsw) {
    this._goToBounds(bounds);
  } else if (count == 1) {
    this._goToBounds(this.maxBounds);

    this.google.map.panTo(singleMarker.marker.getPosition());

    this._toggleMarkerActive(null, singleMarker.marker, singleMarker.feature);
  } else {
    this._goToBounds(this.maxBounds);

    this.google.map.panTo(this._getCenter(bounds));
  }
};

ATPMap.prototype._goToBounds = function (bounds) {
  this.google.map.fitBounds(bounds);
  this.google.map.panTo(this._getCenter(bounds));
};

ATPMap.prototype._getCenter = function (bounds) {
  return bounds.north
    ? {
        lat: (bounds.north + bounds.south) / 2,
        lng: (bounds.east + bounds.west) / 2,
      }
    : bounds.getCenter();
};

ATPMap.prototype._updateResultsList = function () {
  var _this4 = this;

  var list = this.results.list.querySelector('ul'),
    svg = this.results.querySelector('.js-result-svg');
  list.innerHTML = '';

  if (Object.keys(this.markers).length > 0) {
    Object.keys(_this4.markers)
      .map(function (key) {
        return _this4.markers[key];
      })
      .forEach(
        function (obj) {
          var feature = obj.feature,
            item = document.createElement('LI'),
            anchor = document.createElement('A'),
            content =
              '<span class="atp-search__results-container">' +
              '<span class="atp-search__results-icon">' +
              svg.innerHTML +
              '</span>' +
              '<span class="atp-search__results-summary">' +
              '<span class="atp-search__results-title">' +
              feature.getProperty('providerName') +
              '</span>' +
              '<span class="atp-search__results-caption">' +
              feature.getProperty('courses').split('|').join(' & ') +
              '</span>' +
              '<span class="atp-search__results-address">' +
              feature.getProperty('address') +
              '</span>' +
              '</span>' +
              '</span>';
          anchor.classList.add('js-atp-address');
          anchor.setAttribute('href', '#');
          anchor.setAttribute('data-latlng', feature.getProperty('latLng'));
          anchor.addEventListener('click', this._goToMarker.bind(this));
          anchor.addEventListener('click', this._mapToggle.bind(this));
          anchor.innerHTML = content;
          item.appendChild(anchor);
          list.appendChild(item);
        }.bind(this)
      );
  } else {
    list.innerHTML = '<li>ATP not found</li>';
  }
};

ATPMap.prototype._toggleResult = function (feature, toggle) {
  var pos = feature.getProperty('latLng'),
    result = this.results.list.querySelector(
      '.js-atp-address[data-latlng="' + pos + '"]'
    );

  if (result) {
    if (toggle) {
      result.classList.remove('hidden');
    } else {
      result.classList.add('hidden');
    }
  }
};
/**
 * Filters markers and determines if they should be hidden or not
 * If a single marker is left, return it
 */

ATPMap.prototype._filterMarkers = function () {
  var _this5 = this;

  this._buildFilterRegex();

  var singleMarker = [];
  Object.keys(_this5.markers)
    .map(function (key) {
      return _this5.markers[key];
    })
    .forEach(
      function (obj) {
        var toggle = false,
          feature = obj.feature;

        if (this.filterRegex.test(feature.getProperty('courses'))) {
          toggle = true;
        }

        if (toggle) {
          singleMarker.push(obj);
        }

        this._toggleMarkerVisible(obj.marker, toggle);

        this._toggleResult(obj.feature, toggle);
      }.bind(this)
    );
  this.markerCluster.repaint();

  if (singleMarker.length < 1 && Object.keys(this.markers).length > 0) {
    this.results.message.classList.remove('hidden');
  } else {
    this.results.message.classList.add('hidden');
  }

  return singleMarker.length == 1 ? singleMarker[0] : false;
};
/**
 * set active class on selected buttons
 */

ATPMap.prototype._handleTypeToggle = function () {
  Array.prototype.slice
    .call(this.searchType.querySelectorAll('button'))
    .forEach(
      function (btn) {
        btn.addEventListener(
          'click',
          function (e) {
            var target = e.currentTarget;
            Array.prototype.slice
              .call(this.searchType.querySelectorAll('button'))
              .forEach(
                function (sibling) {
                  sibling.classList.remove('active');
                }.bind(this)
              );
            target.classList.add('active');
          }.bind(this)
        );
      }.bind(this)
    );
  this.searchInput =
    typeof this.searchInput != 'string'
      ? this.searchInput
      : Array.prototype.slice.call(
          this.module.querySelectorAll(this.searchInput)
        );
  this.addressInput =
    typeof this.addressInput != 'string'
      ? this.addressInput
      : Array.prototype.slice.call(
          this.module.querySelectorAll(this.addressInput)
        );
  this.searchToggle.addEventListener(
    'click',
    function (e) {
      e.preventDefault();
      this.addressInput.classList.add('hidden');
      this.searchInput.classList.remove('hidden');
      this.autocompleteInput.classList.remove('hidden');
      this.form.setAttribute('data-endpoint', 'funnelback');
    }.bind(this)
  );
  this.addressToggle.addEventListener(
    'click',
    this._activateAddressInput.bind(this)
  );
  this.addressSelect.addEventListener(
    'click',
    this._activateAddressInput.bind(this)
  );
  this.addressSelect.addEventListener(
    'keydown',
    this._handleAddressRangeKeyEvent.bind(this)
  );
};

ATPMap.prototype._activateAddressInput = function (e) {
  e.preventDefault();
  this.searchInput.classList.add('hidden');
  this.autocompleteInput.classList.add('hidden');
  this.addressInput.classList.remove('hidden');
  this.addressToggle.classList.add('active');
  this.addressSelect.classList.add('active');
  this.form.setAttribute('data-endpoint', 'google');
};

ATPMap.prototype._handleAddressRangeKeyEvent = function (e) {
  var keyDown = e.key == 'Space' || e.key == 'Enter';
  if (keyDown) {
    e.preventDefault();
    this._openAddressRangeMenu();
  }
};

ATPMap.prototype._handleNotAddressRangesClick = function (e) {
  var target = e.target;

  if (
    target != this.addressSelect &&
    target != this.addressRanges &&
    !this.addressRanges.contains(target)
  ) {
    this._closeAddressRangeMenu();
  }
};

ATPMap.prototype._toggleAddressRangeMenu = function () {
  if (this.addressRanges.classList.contains('active')) {
    this._closeAddressRangeMenu();
  } else {
    this._openAddressRangeMenu();
  }
};

ATPMap.prototype._openAddressRangeMenu = function () {
  this.addressRanges.classList.add('active');
};

ATPMap.prototype._closeAddressRangeMenu = function () {
  this.addressRanges.classList.remove('active');
};

ATPMap.prototype._updateAddressRangeSelection = function (e) {
  var target = e.currentTarget,
    keyDown = e.key == 'Space' || e.key == 'Enter' || e.key == undefined,
    content = target.innerHTML,
    value = target.getAttribute('data-range');

  if (target && keyDown) {
    e.preventDefault();
    this.addressSelect.setAttribute('data-range', value);
    this.addressSelect.innerHTML = content;
    this._closeAddressRangeMenu();
  }
};

ATPMap.prototype._toggleFilters = function (e) {
  e.preventDefault();
  var target = e.currentTarget;

  if (target.classList.contains('active')) {
    this._hideFilters();
  } else {
    this._showFilters();
  }
};

ATPMap.prototype._showFilters = function () {
  this.filterButton.classList.add('active');
  this.filters.classList.add('active');
};

ATPMap.prototype._hideFilters = function () {
  this.filterButton.classList.remove('active');
  this.filters.classList.remove('active');
};

ATPMap.prototype._handleNotFilterClick = function (e) {
  var target = e.target;

  if (
    target != this.filterButton &&
    target != this.filters &&
    !this.filters.contains(target)
  ) {
    this._hideFilters();
  }
};

(function ($) {
  'use strict';
  if (document.querySelector('.js-atp-search')) {
    const map = new ATPMap();
    map.init();
  }
})(jQuery);
