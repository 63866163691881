(function ($) {
    'use strict';

    var $filterToggles = $('.filters button'),
        $filtersForm = $('.filters form');

    $filterToggles.each(function () {
        var $filterToggle = $(this),
            $filter = $filterToggle.closest('.filters__item'),
            $filterList = $filter.find('ul'),
            $filterField = $filter.find('input'),
            $filterLinks = $filter.find('a');

        if ($filterToggle.attr('type') == 'reset') {
            $filterToggle.click(function () {
                $('.filters button').closest('.filters__item').find('input').val('');
                $filtersForm.submit();
                return false;
            });
        } else {
            $filterToggle.click(function () {
                if ($filterToggle.hasClass('active')) {
                    $filterToggle.removeClass('active');
                    $filterList.slideUp(300, function () {
                        $filterList.removeAttr('style').removeClass('active');
                    });
                } else {
                    $filterToggle.addClass('active');
                    $filterList.slideDown(300, function () {
                        $filterList.addClass('active').removeAttr('style');
                    });
                }

                return false;
            });

            if ($filtersForm.length) {
                $filterLinks.click(function () {
                    var $filterLink = $(this),
                        filterName = $filterLink.text(),
                        filterValue = $filterLink.attr('data-value');

                    $filterField.val(filterValue);
                    $filterToggle.text(filterName).click();
                    $filtersForm.submit();

                    return false;
                });
            }
        }
    });
}(jQuery));