(function($){
    'use strict';

    var $collapsableBlocks = $('.block--collapsable'),
        $headerToggle = $('.header__toggle');

    if($collapsableBlocks.length) {
    	$collapsableBlocks.each(function() {
    		var $collapsableBlock = $(this);

    		$collapsableBlock.append('<div class="block__more"><button>View more</button></div>');

    		$collapsableBlock.find('.block__more button').click(function() {
    			$collapsableBlock.removeClass('collapsed');
    		});

            checkBlock($collapsableBlock);
    	});

		$(window).resize(function() {
	    	$collapsableBlocks.each(function() {
	    		var $thisBlock = $(this);

                checkBlock($thisBlock);
	    	});
		});
    }

    function checkBlock($block) {
        var maxHeight = 394;

        if($headerToggle.is(':visible')) {
            maxHeight = 500;
        }
        
        $block.removeClass('collapsed');
        if($block.height() > maxHeight) {
            $block.addClass('collapsed');
        }
    }
   
}(jQuery));