(function($){
    'use strict';

    var $html = $('html'),
        $body = $('body'),
    	$headerToggle = $('.header__toggle'),
    	$navigation = $('.navigation'),
        $navigationLevel1Toggles = $('.navigation__level-1-link button'),
        $navigationLevel2Toggles = $('.navigation__level-2-link button'),
        navigationSpaceState = true;

    $navigationLevel1Toggles.each(function() {
        var $navigationLevel1Toggle = $(this),
            $navigationLevel2 = $navigationLevel1Toggle.closest('.navigation__level-1-link').siblings('.navigation__level-2-wrapper');
    
        $navigationLevel1Toggle.click(function() {
            if($navigationLevel1Toggle.hasClass('active')) {
                $navigationLevel1Toggle.removeClass('active');
                if($headerToggle.is(':visible')) {
                    $navigationLevel2.slideUp(300, function() {
                        $navigationLevel2.removeAttr('style').removeClass('active');
                    });
                } else {
                    $navigationLevel2.removeClass('active');
                }
            } else {
                if($headerToggle.is(':visible')) {
                    $navigationLevel1Toggle.addClass('active');
                    $navigationLevel2.slideDown(300, function() {
                        $navigationLevel2.addClass('active').removeAttr('style');
                    });
                }
            }

            return false;
        }).keydown(function(evt) {
            if(!$headerToggle.is(':visible')) {
                if (evt.keyCode == 32 || evt.keyCode == 13) {
                    navigationSpaceState = true;

                    if($navigationLevel1Toggle.hasClass('active')) {
                        $navigationLevel1Toggle.removeClass('active');
                        if($headerToggle.is(':visible')) {
                            $navigationLevel2.slideUp(300, function() {
                                $navigationLevel2.removeAttr('style').removeClass('active');
                            });
                        } else {
                            $navigationLevel2.removeClass('active');
                        }
                    } else {
                        if($headerToggle.is(':visible')) {
                            $navigationLevel1Toggle.addClass('active');
                            $navigationLevel2.slideDown(300, function() {
                                $navigationLevel2.addClass('active').removeAttr('style');
                            });
                        } else {
                            $navigation.find('.active').removeClass('active');
                            $navigationLevel2.addClass('active');
                            $navigationLevel1Toggle.addClass('active');
                        }
                    }

                    return false;
                }
            }
        }).on('touchstart', function(){ // Open level 1 navigation on large touch devices
            if(!$headerToggle.is(':visible')) {
                if($navigationLevel1Toggle.hasClass('active')) {
                    $navigationLevel1Toggle.removeClass('active');
                    $navigationLevel2.removeClass('active');
                } else {
                    $navigation.find('.active').removeClass('active');
                    $navigationLevel2.addClass('active');
                    $navigationLevel1Toggle.addClass('active');
                }

                return false;
            }
        });
    });

    $navigationLevel2Toggles.each(function() {
        var $navigationLevel2Toggle = $(this),
            $navigationLevel2Item = $navigationLevel2Toggle.closest('li'),
            $navigationLevel2Link = $navigationLevel2Toggle.siblings('a'),
            $navigationLevel3 = $navigationLevel2Item.find('.navigation__level-3');

        if($navigationLevel3.length) {
            $navigationLevel2Toggle.click(function() {
                if($navigationLevel2Toggle.hasClass('active')) {
                    $navigationLevel2Toggle.removeClass('active');
                    $navigationLevel2Item.removeClass('active');
                    $navigationLevel3.slideUp(300, function() {
                        $navigationLevel3.removeAttr('style').removeClass('active');
                    });
                } else {
                    $navigationLevel2Toggle.addClass('active');
                    $navigationLevel2Item.addClass('active');
                    $navigationLevel3.slideDown(300, function() {
                        $navigationLevel3.addClass('active').removeAttr('style');
                    });
                }

                return false;
            });

            $navigationLevel2Link.keyup(function(evt) {// Open/Close level 3 navigation when using keyboard
                if(!$headerToggle.is(':visible')) {
                    if (evt.keyCode == 32) {
                        navigationSpaceState = false;
                    }
                }
            }).keydown(function(evt) {
                if(!$headerToggle.is(':visible')) {
                    if (evt.keyCode == 32) {
                        navigationSpaceState = true;
                        
                        if($navigationLevel2Link.hasClass('active')) {
                            $navigationLevel2Link.removeClass('active');
                            $navigationLevel3.removeClass('active');
                        } else {
                            $navigationLevel2Link.closest('ul').find('.active').removeClass('active');
                            $navigationLevel2Link.addClass('active');
                            $navigationLevel3.addClass('active');
                        }

                        return false;
                    }
                }
            }).on('touchstart', function(){ // Open level 3 navigation on large touch devices
                if(!$headerToggle.is(':visible')) {
                    if(!$navigationLevel2Link.hasClass('active')) {
                        $navigationLevel2Link.closest('ul').find('.active').removeClass('active');
                        $navigationLevel2Link.addClass('active');
                        $navigationLevel3.addClass('active');
                        return false;
                    }
                }
            });
        } else {
            $navigationLevel2Toggle.remove();
        }
    
    });

    $headerToggle.click(function() {
        if($headerToggle.hasClass('active')) {
            closeNavigation();
        } else {
            openNavigation();
        }

        return false;
    });

    adjustNavigationHeight();
    $(window).resize(function() {
        adjustNavigationHeight();
    });

    function openNavigation() {
        $body.addClass('fixed');
        $headerToggle.addClass('active');
        $navigation.slideDown(300, function() {
            $navigation.addClass('active').removeAttr('style');
        });
    }

    function closeNavigation() {
        $body.removeClass('fixed');
        $headerToggle.removeClass('active');
        $navigation.slideUp(300, function() {
            $navigation.removeClass('active').removeAttr('style');
        });
    }

    function adjustNavigationHeight() {
        if(!$headerToggle.is(':visible')) {
            $('.navigation__level-3').each(function() {
                var $navigationItem = $(this);

                if($navigationItem.height() > 468) {
                    var minHeight = $navigationItem.height() + 68,
                        $navigationWrapper = $navigationItem.closest('.navigation__level-2-wrapper');
                    if($navigationWrapper.height() < minHeight) {
                        $navigationWrapper.css('height', minHeight);
                    }
                }
            });
        }
    }

    
}(jQuery));