(function($){
    'use strict';

    var $sideNavigation = $('.side-navigation'),
        $sideNavigationTitle = $('.side-navigation__title'),
        $sideNavigationLevel1 = $('.side-navigation__level-1'),
        $sideNavigationLevel1Toggles = $('.side-navigation__level-1-link button'),
        $sideNavigationLevel2Toggles = $('.side-navigation__level-2-link button'),
        $sideNavigationLevel3Toggles = $('.side-navigation__level-3-link button'),
        $sideNavigationLevel4Toggles = $('.side-navigation__level-4-link button');

    $sideNavigationTitle.find('button').click(function() {
        if($sideNavigationTitle.hasClass('active')) {
            $sideNavigationTitle.removeClass('active');
            $sideNavigationLevel1.slideUp(300, function() {
                $sideNavigationLevel1.removeClass('active').removeAttr('style');
            });
        } else {
            $sideNavigationTitle.addClass('active');
            $sideNavigationLevel1.slideDown(300, function() {
                $sideNavigationLevel1.addClass('active').removeAttr('style');
            });
        }

        return false;
    });

    $sideNavigationLevel1Toggles.each(function() {
        var $sideNavigationLevel1Toggle = $(this),
            $sideNavigationLevel1Link = $sideNavigationLevel1Toggle.closest('.side-navigation__level-1-link'),
            $sideNavigationLevel2 = $sideNavigationLevel1Link.siblings('.side-navigation__level-2');

        if($sideNavigationLevel2.length) {
            if($sideNavigationLevel1Link.hasClass('active')) {
                $sideNavigationLevel2.addClass('active');
            }

            $sideNavigationLevel1Toggle.click(function() {
                if($sideNavigationLevel1Link.hasClass('active')) {
                    $sideNavigationLevel1Link.removeClass('active');
                    $sideNavigationLevel2.slideUp(300, function() {
                        $sideNavigationLevel2.removeAttr('style').removeClass('active');
                    });
                } else {
                    $sideNavigationLevel1Link.addClass('active');
                    $sideNavigationLevel2.slideDown(300, function() {
                        $sideNavigationLevel2.addClass('active').removeAttr('style');
                    });
                }

                return false;
            });            
        } else {
            $sideNavigationLevel1Toggle.remove();
        }
    
    });

    $sideNavigationLevel2Toggles.each(function() {
        var $sideNavigationLevel2Toggle = $(this),
            $sideNavigationLevel2Link = $sideNavigationLevel2Toggle.closest('.side-navigation__level-2-link'),
            $sideNavigationLevel3 = $sideNavigationLevel2Link.siblings('.side-navigation__level-3');
    
        if($sideNavigationLevel3.length) {
            if($sideNavigationLevel2Link.hasClass('active')) {
                $sideNavigationLevel3.addClass('active');
            }
            
            $sideNavigationLevel2Toggle.click(function() {
                if($sideNavigationLevel2Link.hasClass('active')) {
                    $sideNavigationLevel2Link.removeClass('active');
                    $sideNavigationLevel3.slideUp(300, function() {
                        $sideNavigationLevel3.removeAttr('style').removeClass('active');
                    });
                } else {
                    $sideNavigationLevel2Link.addClass('active');
                    $sideNavigationLevel3.slideDown(300, function() {
                        $sideNavigationLevel3.addClass('active').removeAttr('style');
                    });
                }

                return false;
            });          
        } else {
            $sideNavigationLevel2Toggle.remove();
        }
    });

    $sideNavigationLevel3Toggles.each(function() {
        var $sideNavigationLevel3Toggle = $(this),
            $sideNavigationLevel3Link = $sideNavigationLevel3Toggle.closest('.side-navigation__level-3-link'),
            $sideNavigationLevel4 = $sideNavigationLevel3Link.siblings('.side-navigation__level-4');
    
        if($sideNavigationLevel4.length) {
            if($sideNavigationLevel3Link.hasClass('active')) {
                $sideNavigationLevel4.addClass('active');
            }
            
            $sideNavigationLevel3Toggle.click(function() {
                if($sideNavigationLevel3Link.hasClass('active')) {
                    $sideNavigationLevel3Link.removeClass('active');
                    $sideNavigationLevel4.slideUp(300, function() {
                        $sideNavigationLevel4.removeAttr('style').removeClass('active');
                    });
                } else {
                    $sideNavigationLevel3Link.addClass('active');
                    $sideNavigationLevel4.slideDown(300, function() {
                        $sideNavigationLevel4.addClass('active').removeAttr('style');
                    });
                }

                return false;
            });      
        } else {
            $sideNavigationLevel3Toggle.remove();
        }
    });

    $sideNavigationLevel4Toggles.each(function() {
        var $sideNavigationLevel4Toggle = $(this),
            $sideNavigationLevel4Link = $sideNavigationLevel4Toggle.closest('.side-navigation__level-4-link'),
            $sideNavigationLevel5 = $sideNavigationLevel4Link.siblings('.side-navigation__level-5');
    
        if($sideNavigationLevel5.length) {
            if($sideNavigationLevel4Link.hasClass('active')) {
                $sideNavigationLevel5.addClass('active');
            }
            
            $sideNavigationLevel4Toggle.click(function() {
                if($sideNavigationLevel4Link.hasClass('active')) {
                    $sideNavigationLevel4Link.removeClass('active');
                    $sideNavigationLevel5.slideUp(300, function() {
                        $sideNavigationLevel5.removeAttr('style').removeClass('active');
                    });
                } else {
                    $sideNavigationLevel4Link.addClass('active');
                    $sideNavigationLevel5.slideDown(300, function() {
                        $sideNavigationLevel5.addClass('active').removeAttr('style');
                    });
                }

                return false;
            }); 
        } else {
            $sideNavigationLevel4Toggle.remove();
        }
    });
    
}(jQuery));