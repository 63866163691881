(function($){
    'use strict';

    var $html = $('html'),
        $header = $('.header'),
        $headerSkip = $('.header__accessibility-skip a'),
        $headerTip = $('.header__accessibility-tip span'),
        $headerNavToggle = $('.header__toggle'),
        $headerSearchToggle = $('.header__search-toggle button'),
        $headerSearch = $('.header__search');

    $headerSkip.focusin(function(){ //Activating the nav tip when user uses keyboard
        $headerTip.removeClass('active');
    }).focusout(function(){
        $headerTip.addClass('active');
    }).click(function(){
        var $anchorContent = $($(this).attr('href'));

        $('html, body').animate({
            scrollTop: $anchorContent.offset().top
        }, 300, function(){
            $anchorContent.attr('tabindex', -1).on('blur focusout', function () {
                $(this).removeAttr('tabindex');
            }).focus();
        });

        $header.addClass('static');

        return false;
    });

    $headerSearchToggle.click(function() {
        if($headerSearchToggle.hasClass('active')) {
            $headerSearchToggle.removeClass('active');
            $headerSearch.slideUp(300, function() {
                $headerSearch.removeAttr('style').removeClass('active');
            });
        } else {
            if($headerNavToggle.is(':visible') && $headerNavToggle.hasClass('active')) {
                $headerNavToggle.find('button').click();
            }

            $headerSearchToggle.addClass('active');
            $headerSearch.slideDown(300, function() {
                $headerSearch.addClass('active').removeAttr('style');
            });
        }

        return false;
    });
}(jQuery));