(function($){
    'use strict';

    var $videoBlocks = $('.video-block');

    if($videoBlocks.length) {
    	$videoBlocks.each(function() {
    		var $videoBlock = $(this),
    			$videoIframe = $videoBlock.find('iframe'),
    			videoUrl = $videoIframe.attr('src');

			if (videoUrl.indexOf('youtube') > -1 ) { //YouTube
				var videoID = matchYoutubeUrl(videoUrl);

				$videoBlock.find('div').append('<button style="background-image: url(https://img.youtube.com/vi/' + videoID + '/0.jpg)" class=""><span class="sr-only">Play Video</span></button>');
				$videoIframe.addClass('poster');

				$videoBlock.find('button').click(function() {
					var $thisButton = $(this),
						$thisIframe = $thisButton.siblings('iframe'),
						$newIframe = $thisIframe.clone().attr('src' , $thisIframe.attr('src') + '&autoplay=1').removeClass('poster');

					$thisIframe.remove();
					$thisButton.before($newIframe);
					$thisButton.remove();
				});
			}
    	});

		function matchYoutubeUrl(url){
			var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
			return (url.match(p)) ? RegExp.$1 : false ;
		}
    }
}(jQuery));