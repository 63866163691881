/*global jQuery*/
/**
 * {{name}}
 * Global JS
 *
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * change log:
 *     {{date}} - First revision
 */

/*
 * Table of Contents
 *
 * - Global
 * - Modules
{{toc}}
 */
'use strict';
/*
--------------------
Global
--------------------
*/
//  Declare JS Enabled.
$('body').removeClass('no-js').addClass('js-enabled');

$('table.responsive').each(function () {
	var $table = $(this);

	$table.addClass('table').wrap('<div class="table__wrapper"><div class="table__inner"></div></div>');

	var $tableInner = $table.closest('.table__inner'),
		$tableWrapper = $table.closest('.table__wrapper');

	$tableInner.scroll(function () {
		var tableRect = $table[0].getBoundingClientRect(),
			tableInnerRect = $tableInner[0].getBoundingClientRect();

		if (tableRect.right <= tableInnerRect.right + 1) {
			$tableWrapper.removeClass('scrolling');
		} else {
			$tableWrapper.addClass('scrolling');
		}
	});

	if ($tableInner.width() == $table.width()) {
		$tableWrapper.removeClass('scrolling');
	} else {
		var tableRect = $table[0].getBoundingClientRect(),
			tableInnerRect = $tableInner[0].getBoundingClientRect();

		if (tableRect.right <= tableInnerRect.right + 1) {
			$tableWrapper.removeClass('scrolling');
		} else {
			$tableWrapper.addClass('scrolling');
		}
	}

	$(window).resize(function () {
		if ($tableInner.width() == $table.width()) {
			$tableWrapper.removeClass('scrolling');
		} else {
			var tableRect = $table[0].getBoundingClientRect(),
				tableInnerRect = $tableInner[0].getBoundingClientRect();

			if (tableRect.right <= tableInnerRect.right + 1) {
				$tableWrapper.removeClass('scrolling');
			} else {
				$tableWrapper.addClass('scrolling');
			}
		}
	});
});

$('a').each(function () {
	const currentHost = window.location.hostname;
	const linkHref = $(this).prop('href');
	if (linkHref.indexOf(currentHost) < 0) {
		$(this).prop('target', '_blank').addClass('external-link');
		$(this).prop('target', '_blank');
	}
});

function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

/*
--------------------
Modules
--------------------
*/