(function($){
    'use strict';

	$('.gallery').each(function() {
		var $galleryWrapper = $(this).find('.gallery__wrapper');

		$galleryWrapper.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			dots: true,
			arrows: true,
			centerMode: false,
			mobileFirst: true
		});
	});
}(jQuery));