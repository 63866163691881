(function($){
    'use strict';

    $('.quick-links button').each(function() {
		var $quickLinksToggle = $(this);

		$quickLinksToggle.click(function() {
			var $quickLinksContent = $quickLinksToggle.closest('.quick-links__item').find('.quick-links__content');

			if($quickLinksToggle.hasClass('active')) {
				$quickLinksToggle.removeClass('active');
				$quickLinksContent.slideUp(300, function() {
					$quickLinksContent.removeClass('active').removeAttr('style');
				});
			} else {
				$quickLinksToggle.addClass('active');
				$quickLinksContent.slideDown(300, function() {
					$quickLinksContent.addClass('active').removeAttr('style');
				});
			}

			return false;
		});
	});
}(jQuery));